var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-project-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "app-welcome-message" }, [
            _c("h1", [_vm._v("Informatieplicht")]),
            _c("p", [
              _vm._v("Maak een RVO uploadbestand tbv de informatieplicht"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-alert",
        {
          attrs: {
            value: !_setup.masterDataLoaded,
            dense: "",
            text: "",
            outlined: "",
            type: "info",
          },
        },
        [_vm._v("Maatregelen controleren...")]
      ),
      _setup.masterDataLoaded && !_setup.partyHasCertifiedMeasures
        ? _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                outlined: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [_vm._v("Deze partij heeft geen erkende maatregelen.")]
          )
        : _vm._e(),
      _setup.measuresWithError == 1
        ? _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                outlined: "",
                type: "error",
                icon: "mdi-alert-octagon",
              },
            },
            [_vm._v("Er is 1 probleem m.b.t. maatregelen.")]
          )
        : _setup.measuresWithError > 1
        ? _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                outlined: "",
                type: "error",
                icon: "mdi-alert-octagon",
              },
            },
            [
              _vm._v(
                "Er zijn " +
                  _vm._s(_setup.measuresWithError) +
                  " problemen m.b.t. maatregelen."
              ),
            ]
          )
        : _vm._e(),
      _setup.measuresWithWarning == 1
        ? _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                outlined: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [_vm._v("Er is 1 waarschuwing m.b.t. maatregelen.")]
          )
        : _setup.measuresWithWarning > 1
        ? _c(
            "v-alert",
            {
              attrs: {
                dense: "",
                text: "",
                outlined: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [
              _vm._v(
                "Er zijn " +
                  _vm._s(_setup.measuresWithWarning) +
                  " waarschuwingen m.b.t. maatregelen."
              ),
            ]
          )
        : _vm._e(),
      _setup.measuresWithInfo == 1
        ? _c(
            "v-alert",
            { attrs: { dense: "", text: "", outlined: "", type: "info" } },
            [_vm._v("Er is 1 informatie berichten m.b.t. maatregelen.")]
          )
        : _setup.measuresWithInfo > 1
        ? _c(
            "v-alert",
            { attrs: { dense: "", text: "", outlined: "", type: "info" } },
            [
              _vm._v(
                "Er zijn " +
                  _vm._s(_setup.measuresWithInfo) +
                  " informatie berichten m.b.t. maatregelen."
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-alert",
        {
          attrs: {
            value: _setup.masterDataLoaded && _setup.measuresMessages == 0,
            dense: "",
            text: "",
            outlined: "",
            type: "info",
          },
        },
        [_vm._v("Geen meldingen bij controle.")]
      ),
      _setup.measuresMessages > 0
        ? _c(_setup.ProjectMeasuresCheckInfo, {
            attrs: {
              partyReference: _setup.userStore.currentPartyReference.toString(),
            },
          })
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { "mb-4": "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: {
                disabled:
                  !_setup.masterDataLoaded ||
                  !_setup.partyHasCertifiedMeasures ||
                  _setup.disableCreateBulkUploadXML,
              },
              on: { click: _setup.createXML },
            },
            [
              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                _vm._v("mdi-file-download"),
              ]),
              _vm._v(" Creëer bestand "),
            ],
            1
          ),
        ],
        1
      ),
      _setup.totalMessages.length > 0
        ? _vm._l(_setup.totalMessages, function (item, index) {
            return _c(
              "v-alert",
              {
                key: `total-${index}`,
                attrs: { dense: "", text: "", outlined: "", type: "info" },
              },
              [_vm._v(_vm._s(item))]
            )
          })
        : _vm._e(),
      _setup.errorMessages.length > 0
        ? _vm._l(_setup.errorMessages, function (item, index) {
            return _c(
              "v-alert",
              {
                key: `total-${index}`,
                attrs: {
                  dense: "",
                  text: "",
                  outlined: "",
                  type: "error",
                  icon: "mdi-alert-octagon",
                },
              },
              [_vm._v(_vm._s(item))]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }