var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", [_c("h4", [_vm._v("Meldingen")])]),
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.edit`,
              fn: function ({ item }) {
                return [
                  !_setup.isEmptyGuid(item.appliedId)
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            small: "",
                            color: "primary",
                            to: {
                              name: "AppliedDetails",
                              params: { appliedId: item.appliedId },
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-pencil "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.responseCode`,
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    {
                      class: {
                        "alert alert-error":
                          item.responseCode >= 400 && item.responseCode <= 499,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_setup.msgMeasureCheckCode(item.responseCode))
                      ),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }