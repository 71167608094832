<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { hasCertifiedMeasures, getMeasuresCheckInfoSummary, createBulkUploadXML } from "@/services/api/project.api";
import ProjectMeasuresCheckInfo from "@/components/app/projects/ProjectMeasuresCheckInfo";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const partyHasCertifiedMeasures = ref(false);
const measuresMessages = ref(0);
const measuresWithInfo = ref(0);
const measuresWithWarning = ref(0);
const measuresWithError = ref(0);
const xmlData = ref({
  initials: "",
  middlename: "",
  lastname: "",
});
const dataLoaded = ref(false);
const totalMessages = ref([]);
const errorMessages = ref([]);

watch(
  () => userStore.currentPartyReference,
  () => {
    dataLoaded.value = false;
    totalMessages.value.splice(0);
    errorMessages.value.splice(0);
    checkPartyHasCertifiedMeasures();
    measuresCheckInfoSummary();
  },
  { immediate: true }
);

const masterDataLoaded = computed(() => dataLoaded.value);

const disableCreateBulkUploadXML = computed(() => measuresWithError.value > 0);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function checkPartyHasCertifiedMeasures() {
  const response = await hasCertifiedMeasures(userStore.currentPartyReference);
  partyHasCertifiedMeasures.value = response.data;
}

async function measuresCheckInfoSummary() {
  measuresMessages.value = 0;
  measuresWithInfo.value = 0;
  measuresWithWarning.value = 0;
  measuresWithError.value = 0;
  dataLoaded.value = false;

  const response = await getMeasuresCheckInfoSummary(userStore.currentPartyReference);
  //console.log("ProjectCreateBulkUploadXML, createXML, response: ", response.data);
  if (response && response.data.success) {
    measuresMessages.value = response.data.totalNumberOfMessages;
    measuresWithInfo.value = response.data.numberOfInformationals;
    measuresWithWarning.value = response.data.numberOfWarnings;
    measuresWithError.value = response.data.numberOfErrors;
    dataLoaded.value = true;
  } else {
    proxy.$toaster.error("Probleem bij het controleren van maatregelen.");
  }
}

// async function submit() {
//   if (validate()) {
//     await createXML();
//   }
// }

// function validate() {
//   if (xmlData.value.initials == undefined || xmlData.value.lastname == undefined) {
//     proxy.$toaster.error("Vul de gegevens van de inrichtingsverantwoordelijke is.");
//     return false;
//   }
//   return true;
// }

async function createXML() {
  const response = await createBulkUploadXML(userStore.currentPartyReference, xmlData.value);

  console.log("ProjectCreateBulkUploadXML, createXML, response: ", response.data);

  if (response && response.data.success) {
    if (response.data.xmlString) {
      await saveToFile(response.data.xmlString, response.data.fileName);
      proxy.$toaster.success("RVO bestand is gecreëerd", { timeout: 10000 });
    }

    if (response.data.messages) {
      totalMessages.value.splice(0);
      errorMessages.value.splice(0);
      response.data.messages.forEach((item) => {
        switch (item.code) {
          case "Total":
            totalMessages.value.push(`Aantal panden: ${item.values[0]}`);
            break;
          case "TotalSucces":
            totalMessages.value.push(`Aantal succesvolle panden: ${item.values[0]}`);
            break;
          case "TotalNoConsumption":
            totalMessages.value.push(`Aantal panden zonder jaarlijkse energieverbruiken: ${item.values[0]}`);
            break;
          case "TotalNoCategories":
            totalMessages.value.push(`Aantal panden zonder erkende maatregelen categorieen: ${item.values[0]}`);
            break;
          case "TotalNoMeasures":
            totalMessages.value.push(`Aantal panden zonder erkende maatregelen: ${item.values[0]}`);
            break;
          case "PartyInvalid":
            errorMessages.value.push(`Partij ongeldig: ${item.values[0]}`);
            break;
          case "PartyContactInvalid":
            errorMessages.value.push(`Geen contactpersoon voor partij`);
            break;
          case "LocationInvalid":
            errorMessages.value.push(`Adres/locatie ongeldig voor: ${item.values[0]}`);
            break;
          case "WeiiInvalid":
            errorMessages.value.push(`Weii ongeldig voor: ${item.values[0]}`);
            break;
          case "EponlineInvalid":
            errorMessages.value.push(`EP-Online label ongeldig voor: ${item.values[0]}`);
            break;
          case "NoConsmption":
            errorMessages.value.push(`Geen verbruiken voor: ${item.values[0]}`);
            break;
          case "SectorInvalid":
            errorMessages.value.push(`Ongeldige sector ${item.values[0]} => ${item.values[1]} voor: ${item.values[2]}`);
            break;
          case "ExecutorInvalid":
            errorMessages.value.push(`Ongeldige uitvoerder voor: ${item.values[0]}`);
            break;
          case "OwnerInvalid":
            errorMessages.value.push(`Ongeldige eigenaar voor: ${item.values[0]}`);
            break;
          case "MeasureInvalid":
            errorMessages.value.push(`Ongeldige maatregel: ${item.values[0]}-${item.values[1]} voor: ${item.values[2]}`);
            break;
          case "MeasureStatusInvalid":
            errorMessages.value.push(`Ongeldige maatregel status: ${item.values[0]}-${item.values[1]} '${item.values[2]}' voor: ${item.values[3]}`);
            break;

          default:
            errorMessages.value.push(`${item.code}`);
            break;
        }
      });
    }
  } else {
    proxy.$toaster.error("Aanmaken bestand is mislukt!");
  }
}

async function saveToFile(data, filename) {
  let blob = new Blob([data], { type: ".xml" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<template>
  <v-container fluid class="app-project-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Informatieplicht</h1>
        <p>Maak een RVO uploadbestand tbv de informatieplicht</p>
      </v-col>
    </v-row>
    <v-alert :value="!masterDataLoaded" dense text outlined type="info">Maatregelen controleren...</v-alert>
    <v-alert v-if="masterDataLoaded && !partyHasCertifiedMeasures" dense text outlined type="warning" icon="mdi-alert">Deze partij heeft geen erkende maatregelen.</v-alert>
    <v-alert v-if="measuresWithError == 1" dense text outlined type="error" icon="mdi-alert-octagon">Er is 1 probleem m.b.t. maatregelen.</v-alert>
    <v-alert v-else-if="measuresWithError > 1" dense text outlined type="error" icon="mdi-alert-octagon">Er zijn {{ measuresWithError }} problemen m.b.t. maatregelen.</v-alert>
    <v-alert v-if="measuresWithWarning == 1" dense text outlined type="warning" icon="mdi-alert">Er is 1 waarschuwing m.b.t. maatregelen.</v-alert>
    <v-alert v-else-if="measuresWithWarning > 1" dense text outlined type="warning" icon="mdi-alert">Er zijn {{ measuresWithWarning }} waarschuwingen m.b.t. maatregelen.</v-alert>
    <v-alert v-if="measuresWithInfo == 1" dense text outlined type="info">Er is 1 informatie berichten m.b.t. maatregelen.</v-alert>
    <v-alert v-else-if="measuresWithInfo > 1" dense text outlined type="info">Er zijn {{ measuresWithInfo }} informatie berichten m.b.t. maatregelen.</v-alert>

    <v-alert :value="masterDataLoaded && measuresMessages == 0" dense text outlined type="info">Geen meldingen bij controle.</v-alert>

    <project-measures-check-info v-if="measuresMessages > 0" :partyReference="userStore.currentPartyReference.toString()"></project-measures-check-info>

    <v-layout mb-4>
      <v-btn :disabled="!masterDataLoaded || !partyHasCertifiedMeasures || disableCreateBulkUploadXML" class="primary" @click="createXML">
        <v-icon dark left>mdi-file-download</v-icon>
        Creëer bestand
      </v-btn>
    </v-layout>

    <template v-if="totalMessages.length > 0">
      <v-alert v-for="(item, index) in totalMessages" :key="`total-${index}`" dense text outlined type="info">{{ item }}</v-alert>
    </template>

    <template v-if="errorMessages.length > 0">
      <v-alert v-for="(item, index) in errorMessages" :key="`total-${index}`" dense text outlined type="error" icon="mdi-alert-octagon">{{ item }}</v-alert>
    </template>

    <!--    <v-row>
          <v-col cols="5">
            <form id="adm-createRvoXml-form" @submit.prevent="submit">
              <v-card class="mx-auto">
          <v-card-title>
                  <header class="app-welcome-message">
                    <h2>Inrichtingsverantwoordelijke</h2>
                  </header>
                </v-card-title>
                <v-card-text>
                  <v-container class="register-form">
                    <v-row>
                      <v-col class="form-group">
                        <label>Initialen</label>
                        <input type="text" class="form-control" name="xmlData-initials" placeholder="Initialen" required v-model="xmlData.initials" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="form-group">
                        <label>Tussenvoegsels</label>
                        <input type="text" class="form-control" name="xmlData-middlename" placeholder="Tussenvoegsels" v-model="xmlData.middlename" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="form-group">
                        <label>Achternaam</label>
                        <input type="text" class="form-control" name="xmlData-lastname" placeholder="Achternaam" required v-model="xmlData.lastname" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
              </v-card>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </v-container>
</template>
