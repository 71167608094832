<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getMeasuresCheckInfoPaged } from "@/services/api/project.api";
import GridPagination from "@/components/general/GridPagination.vue";
import { isEmptyGuid } from "@/composables/miscellaneous.js";
import useMessages from "@/composables/messages.js";
import useGrid27 from "@/composables/grid27.js";

const { msgMeasureCheckCode } = useMessages();
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  partyReference: String,
});

const _headers = [
  {
    text: "",
    align: "left",
    sortable: false,
    value: "edit",
    width: "20",
    showEdit: true,
  },
  { text: "Adres", value: "address" },
  { text: "Hoofdcategorie", value: "categoryMain" },
  { text: "Categorie", value: "category" },
  { text: "Code", value: "definitionCode" },
  { text: "Maatregel", value: "definitionName" },
  { text: "Melding", value: "responseCode" },
];
const headers = computed(() => _headers);

watch(
  () => props.partyReference,
  () => {
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  if (props.partyReference) {
    const pageNumber0 = pageNumber - 1;
    const response = await getMeasuresCheckInfoPaged(props.partyReference, pageNumber0, pageSize, sortBy, sortDesc);
    return response;
  } else {
    return undefined;
  }
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("ProjectMeasuresCheckInfoGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <v-container fluid pa-0>
    <header>
      <h4>Meldingen</h4>
    </header>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn v-if="!isEmptyGuid(item.appliedId)" icon small color="primary" :to="{ name: 'AppliedDetails', params: { appliedId: item.appliedId } }">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.responseCode`]="{ item }">
        <span :class="{ 'alert alert-error': item.responseCode >= 400 && item.responseCode <= 499 }"> {{ msgMeasureCheckCode(item.responseCode) }}</span>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </v-container>
</template>
